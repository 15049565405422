import Image from 'next/image'
import Link from 'next/link'

import styles from './collection-card.module.scss'

export type CollectionCardProps = {
  id: number
  name: string
  image: string
  imageAlt: string
  slug: string
  type: 'grid' | 'carousel'
}

export const CollectionCard = (item: CollectionCardProps) => {
  return (
    <Link href={item.slug}>
      <a className={styles.container} data-type={item.type}>
        <div className={styles.image}>
        <div className={styles.hover}>
            <Image
              src={item.imageAlt}
              alt={item.name}
              layout='fill'
            />
          </div>
          <div className={styles.base}>
            <Image
              src={item.image}
              alt={item.name}
              layout='fill'
            />
          </div>
          <div className={styles.mask}></div>
        </div>
        <div>
          <p className={styles.tag}>Collection</p>
          <p className={styles.label}>{item.name}</p>
        </div>
      </a>
    </Link>
  )
}
